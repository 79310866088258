<!--suppress ALL -->
<template>
  <div>
    <el-drawer
        :before-close="beforeClose"
        :destroy-on-close="true"
        :show-close="true"
        :size="'60%'"
        :visible.sync="drawerVisible"
        :wrapperClosable="false"
        :with-header="true"
    >
      <template slot="title">
        <div class="clearfix" slot="header">
          <span class="title">项目{{ textBtn }}</span>
        </div>
      </template>
      <el-card class="box-card">
        <!--        <div class="clearfix" slot="header">-->
        <!--          <span class="title">项目{{ textBtn }}</span>-->
        <!--        </div>-->
        <div>
          <el-form :model="form" label-width="80px" ref="form">
            <el-form-item label="项目" required style="width: 290px">
              <el-input clearable v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="意向达人">
              <el-checkbox-group v-model="is_assign">
                <el-checkbox :label="1">意向选择</el-checkbox>
              </el-checkbox-group>
              <ArtistSearch @handleSelect="chooseAdArtist" v-if="this.is_assign?.indexOf(1) > -1"/>
              <el-tag
                  :key="tag.id"
                  :type="tag.id"
                  @close="tagClose(index)"
                  closable
                  style="margin-left: 3px"
                  v-for="(tag,index) in intentions">
                {{ tag.nickname }}
              </el-tag>
            </el-form-item>
            <el-row>
              <el-col :span="12">
                <el-form-item label="品牌" style="width: 290px">
                  <el-input v-model="form.brand"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item label="所属集团">
                  <el-select v-model="form.group_id" placeholder="请选择">
                    <el-option
                        v-for="item in belongGroup"
                        :key="item.id"
                        :label="item.display_name"
                        :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="产品" style="max-width: 290px">
                  <el-input v-model="form.product"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item label="是否寄回">
                  <el-switch active-color="#13ce66"
                             inactive-color="#ff4949" v-model="form.is_send_back"></el-switch>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="产品资料">
                  <el-input :autosize="{ minRows: 6, maxRows: 10}" clearable maxlength="200"
                            placeholder="品牌背景/产品售卖链接等" show-word-limit type="textarea"
                            v-model="form.intro"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="附件上传">
                  <!--                                    <el-button>点击上传</el-button>-->
                  <el-upload
                      :before-upload="beforeUpload"
                      :headers="uploadHeaders"
                      :on-change="handleChange"
                      :on-success="importSuccess"
                      :show-file-list="false"
                      action="/admin_api/upload/file"
                      class="upload-demo"
                      multiple
                      :file-list="attachments"
                  >
                    <el-button icon="el-icon-upload" type="primary">点击上传</el-button>
                  </el-upload>
                  <template v-if="attachments?.length>0||false">
                    <span v-for="(item,index) in attachments" :key="item.id">
                       <a :href="item.full_path" target="_blank">{{ item.original || '附件' }}
                         <!--                      <el-button type="danger" icon="el-icon-delete" circle></el-button>-->
                    </a>
                      <i class="el-icon-delete" style="color: red;margin: 0 5px;cursor: pointer"
                         @click="deleteFile(item?.id,index)"></i>
                    </span>
                  </template>
                  <!--                  <br> <span style="color:darkgray ">支持扩展名：.doc .docx .pdf</span>-->
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item label="合作平台">
              <el-checkbox-group v-model="form.platforms">
                <el-checkbox :key="index+'category'" :label="item.name"
                             v-for="(item,index) in platforms"
                >{{ item.display_name }}
                </el-checkbox>
              </el-checkbox-group>
              <el-radio-group v-model="form.package" v-if="form.platforms?.includes('package')">
                <el-radio :key="index+'package'" :label="item?.name"
                          v-for="(item,index) in packageData">
                  {{ item.display_name }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="合作形式" prop="skill_id">
              <el-radio-group v-model="form.coop_id" @change="coopChange">
                <el-radio :key="index+'category'" :label="item.id"
                          v-for="(item,index) in category">
                  {{ item.display_name }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="平台下单">
              <el-row>
                <el-col :span="3">
                  <el-switch
                      active-color="#13ce66"
                      inactive-color="#ff4949"
                      v-model="form.is_platform_pay">
                  </el-switch>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item label="挂车佣金">
              <el-row>
                <el-col :span="3">
                  <el-switch
                      active-color="#13ce66"
                      inactive-color="#ff4949"
                      v-model="form.is_cart">
                  </el-switch>
                </el-col>
                <el-col :span="8">
                  <el-input oninput="value=value.replace(/^\.+|[^\d.]/g,'')" style="width: 200px"
                            type="number"
                            v-if="form.is_cart" v-model="form.cart_rate">
                    <template slot="append">%</template>
                  </el-input>
                </el-col>
                <el-col :span="8" v-if="form.is_cart">
                  <el-form-item label="挂车机制" style="width: 290px">
                    <el-input v-model="form.cart_mechanism"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item label="授权平台">
              <el-row>
                <el-col :span="3">
                  <el-switch
                      active-color="#13ce66"
                      inactive-color="#ff4949"
                      v-model="form.is_auth_price">
                  </el-switch>
                </el-col>
                <el-col :span="10">
                  <el-select v-model="form.auth_platform" style="width: 200px" placeholder="请选择" multiple
                             v-if="form.is_auth_price">
                    <el-option
                        v-for="item in authPlatform"
                        :key="item.id"
                        :label="item.display_name"
                        :value="item.id">
                    </el-option>
                  </el-select>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item label="期望档期" required>
              <el-date-picker
                  :picker-options="pickerOptions"
                  align="right"
                  end-placeholder="结束日期"
                  range-separator="至"
                  start-placeholder="开始日期"
                  type="daterange"
                  v-model="expect_time"
                  value-format="yyyy-MM-dd">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="直播明细" style="width: 830px" :required="form.coop_id===11052">
              <el-input :autosize="{ minRows: 8, maxRows: 10}" clearable maxlength="1000" show-word-limit
                        placeholder="是否走平台/排竞时长/视频保留时长/产品露出时间/是否上脸使用/是否寄送等"
                        type="textarea" v-model="form.live_detail"></el-input>
            </el-form-item>
            <el-form-item label="其他要求" style="width: 830px">
              <el-input :autosize="{ minRows: 8, maxRows: 10}" clearable maxlength="1000" show-word-limit
                        placeholder="是否走平台/排竞时长/视频保留时长/产品露出时间/是否上脸使用/是否寄送等"
                        type="textarea" v-model="form.other_desc"></el-input>
            </el-form-item>
            <el-form-item label="报名时间" required>
              <el-date-picker
                  :picker-options="pickerOptions"
                  align="right"
                  end-placeholder="结束日期"
                  range-separator="至"
                  start-placeholder="开始日期"
                  type="datetimerange"
                  v-model="apply_time"
                  value-format="yyyy-MM-dd HH:mm:ss">
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button @click="onSubmit" type="primary">{{ textBtn }}</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div v-if="this.rowId !== 'add'&&form.is_apply!==2">
          <el-row>
            <el-col :offset="9" :span="3" v-if="form.is_apply!==1">
              <el-button @click="handleVoid(1)" round type="success">开始报名</el-button>
            </el-col>
            <el-col :offset="9" :span="3" v-if="form.is_apply===1">
              <el-button @click="handleVoid(3)" round type="warning">结束报名</el-button>
            </el-col>
          </el-row>
        </div>
      </el-card>
    </el-drawer>
  </div>
</template>

<script>
import ArtistSearch from '@/components/artist/ArtistSearch'
import { getToken } from '@/utils/auth'

export default {
  name: 'CreateEdit',
  data() {
    return {
      form: { auth_platform: [] },
      expect_time: [],//期望日期
      apply_time: [],//报名时间
      platforms: [],//开通平台列表
      opened_platforms: [],//选择的平台
      category: [],//合作形式
      packageData: [],//打包平台
      belongGroup: [],//所属集团
      authPlatform: [],//授权平台
      textBtn: '创建',
      convertData: ['is_platform_pay', 'is_cart', 'is_auth_price'],
      intentions: [],
      uploadHeaders: { 'HYPERF-SESSION-ID': null },
      is_assign: [],
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() + 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [end, start])
          }
        }
          ,
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() + 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [end, start])
            }
          }
          ,
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() + 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [end, start])
            }
          }
        ]
      },
      attachments: [],
      original_name: []
    }
  },
  components: { ArtistSearch },
  props: {
    rowId: {
      type: [Number, String],
      default() {
        return 'add'
      }
    },
    drawerVisible: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  watch: {
    drawerVisible: {
      deep: true,
      handler() {
        this.infoData()
      }
    }

  },
  methods: {
    coopChange(val) {
      console.log(val)
    },
    async deleteFile(val, index) {
      if (this.rowId !== 'add') {
        this.$confirm(`确定是否删除附件`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          this.attachments.splice(index, 1)
          let id = await this.$api.deleteInquiryFile({ id: val })
          if (id) {
            this.$notify.success('删除成功')
          }
        })
      } else {
        this.attachments.splice(index, 1)
      }
    },
    beforeUpload() {
      this.uploadLoading = true
      this.uploadHeaders['HYPERF-SESSION-ID'] = getToken()
    },
    //下载上传模块
    handleChange(file) {
      this.file = [file]
    },
    importSuccess(response) {
      if (response.code === 200) {
        this.$notify.success('上传成功')
        this.attachments.push({
          full_path: response?.data[0]?.full_path,
          original: response.data[0].original,
          attachment_id: response.data[0].id
        })
      } else {
        this.$notify.warning(response.msg)
      }
    },
    async handleVoid(status) {
      // "status": 2 //1:进行中 2:已完结 3:已作废
      //is_apply 报名状态 //1：报名中 2:不可报名，3.结束报名
      let statusData = {
        id: this.rowId,
        status: 1,
        is_apply: status
      }
      let id = await this.$api.switchInquiryStatus(statusData)
      if (id) {
        this.$notify.success(`${status === 1 ? '【开始报名切换】' : '【结束报名切换】'}成功`)
        this.$emit('update:drawerVisible', false)
        await this.infoData()
      }
    },
    chooseAdArtist(item) {
      // JSON.stringify(item) === '{}' ? this.form.artist_id = [] : this.form.artist_id = [item.id]

      if (item && !(JSON.stringify(item) === '{}')) {
        let includes = this.intentions.find((i) => {
          return i.id === item.id
        })
        if (!includes) {
          this.intentions.push({ nickname: item.nickname, id: item.id })
        }
      }
    },
    tagClose(val) {
      this.intentions.splice(val)
    },
    beforeClose() {
      this.$emit('update:drawerVisible', false)
    },
    async infoData() {
      //合作形式
      let data = await this.$api.getCategoryListByIdentify(`inquiry-coop`)
      //合作平台
      let { list } = await this.$api.getCategoryListByIdentify(`inquiry-platforms`)
      //打包数据
      // let pack = await this.$api.getCategoryListByIdentify(`package`)
      //所属集团
      let group = await this.$api.getCategoryListByIdentify(`belong-group`)
      let authPlatform = await this.$api.getCategoryListByIdentify(`auth-platform`)
      this.authPlatform = authPlatform.list
      list.forEach((item) => {
        if (item.display_name === '打包') {
          this.packageData = item.children
        }
      })
      this.belongGroup = group.list
      this.platforms = list
      this.category = data.list
      if (this.rowId !== 'add') {
        //这里是修改的逻辑
        let { info } = await this.$api.infoInquiryProject({ id: this.rowId })
        this.$nextTick(() => {
          this.expect_time = [info['expect_start'], info['except_end']]
          this.apply_time = [info['apply_start'], info['apply_end']]
          this.intentions = info['intentions']
          this.is_assign = [info['is_assign']]
          this.convertData.forEach((i) => {
            info[i] = info[i] !== 1
          })
          this.attachments = info['attachments']
          // info['auth_platform'] = info['auth_platform']?.id
          info['is_send_back'] = info['is_send_back'] === 1
          this.form = info
          this.form.platforms.forEach((item, index) => {
            if (item === this.form.package) {
              // delete item
              this.form.platforms.splice(index, 1)
            }
          })
          // this.platforms.includes('package')
        })
        this.textBtn = '修改'
      } else {
        //报名时间/期望档期/意向达人/附件 - 初始化
        this.expect_time = []
        this.apply_time = []
        this.is_assign = []
        this.intentions = []
        this.attachments = []
        this.form = {
          name: '',
          brand: '',
          product: '',
          intro: '',
          platforms: [],
          is_platform_pay: false,
          coop_id: '',
          is_send_back: false,
          is_cart: false,
          cart_rate: 0,
          is_auth_price: false,
          auth_platform: [],
          expect_start: '',
          except_end: '',
          apply_end: '',
          apply_start: '',
          is_assign: [1],
          original_name: '',
          other_desc: '',
          package: '',
          cart_mechanism: '',
          attachments: [],
          live_detail: ''
        }
        if (list?.length > 0) {
          this.form.platforms = []
          this.form.platforms.push(list[0].name)
        }
        if (data.list?.length > 0) {
          this.form.coop_id = data.list[0].id
        }
        this.textBtn = '创建'
      }
    },
    formDataFilter() {

      let returnData = true
      //在新增的时候转一下 因为默认是true/false
      let newValue = { ...this.form }
      // newValue = this.form
      this.convertData.forEach((i) => {
        //2是true 1：false
        newValue[i] = newValue[i] || newValue[i] === 2 ? 2 : 1
      })
      newValue['is_send_back'] = newValue['is_send_back'] ? 1 : 2
      //初始化内容
      !(newValue['is_cart']) || newValue['is_cart'] === 1 ? newValue['cart_rate'] = 0 : ''
      // returnData = false
      //期望档期
      if (this.expect_time?.length > 0) {
        [newValue.expect_start, newValue.except_end] = this.expect_time
      } else {
        this.$notify.warning('请选择期望档期')
        returnData = false
      }
      if (this.apply_time?.length > 0) {
        [newValue.apply_start, newValue.apply_end] = this.apply_time
      } else {
        this.$notify.warning('请选择报名时间')
        returnData = false
      }
      if (this.form.coop_id === 11052) {
        if (!this.form.other_desc) {
          this.$notify.warning('合作形式为全案时,其他要求必填')
          returnData = false
        }
      }

      let nickIds = []
      //意向达人
      newValue.is_assign = this.is_assign?.indexOf(1) > -1 ? 1 : 2
      if (newValue.is_assign === 1) {
        if (this.intentions?.length > 0) {
          this.intentions.forEach((item) => {
            nickIds.push(item.id)
          })
          newValue.intentions = nickIds
        } else {
          this.$notify({
            title: '警告',
            message: '请选择意向达人',
            type: 'warning'
          })
          returnData = false
        }
      }
      //项目名：
      if (newValue.name === '') {
        this.$notify({
          title: '警告',
          message: '项目名必填',
          type: 'warning'
        })
        returnData = false
      }
      //附件上传
      // attachments
      this.attachments?.length > 0 ? newValue.attachments = this.attachments : []
      newValue.platforms.includes('package') ? newValue.platforms.push(newValue?.package) : ''
      returnData ? this.form = { ...this.form, ...newValue } : ''
      //1:否 2：是
      this.form['is_auth_price'] === 1 ? delete this.form['auth_platform'] : this.form['auth_platform']
      if (this.rowId !== 'add') {
        this.form.attachments = []
        newValue['attachments'].forEach((item) => {
          this.form.attachments.push({
            full_path: item?.full_path,
            original: item.original,
            attachment_id: item.attachment_id
          })
        })
        delete this.form.group
      }
      return returnData
    },
    async onSubmit() {
      if (this.formDataFilter()) {
        if (this.rowId === 'add') {
          let id = await this.$api.saveInquiryProject(this.form)
          if (id) {
            this.$notify.success('添加成功')
            this.$emit('update:drawerVisible', false)
          }
        } else {
          this.form.id = this.rowId
          let id = await this.$api.updateInquiryProject(this.form)
          if (id) {
            this.$notify.success('修改成功')
            this.$emit('update:drawerVisible', false)
          }
        }
      }
    }

  },
  created() {
    this.infoData()
  }
}
</script>
<style lang="scss">
.title {
  font-size: 18px;
  font-weight: 800;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

input[type="number"] {
  -moz-appearance: textfield; /* 此处写不写都可以 */
}
</style>
