<template>
  <div>
    <Search :isType='type' @searchData='searchData'></Search>

    <el-row>
      <el-col :span='1.5'>
        <div style='float: left;margin-right: 10px' @click='addTable' class='ripple_content ripple_success'
             v-if="userPermissions.indexOf('inquiry_create') !== -1">新增询单
        </div>
      </el-col>
      <el-col :span='1.5'>
        <div @click='exportExcel' class='ripple_content ripple_danger'
             v-if="userPermissions.indexOf('inquiry_export') !== -1">
          <i class='el-icon-download'></i>
          导出列表
        </div>
      </el-col>
    </el-row>
    <!--    <el-button  v-loading="downloadLoading" type='primary' icon="el-icon-download" >导出-->
    <!--    </el-button>-->
    <el-tabs @tab-click='handleClick' v-model='activeName'>
      <el-tab-pane :label="`全部(${pageInfo.total??'0'})`"
                   v-if="tableData&&userPermissions.indexOf('inquiry_all_projects') !== -1&&type==='all'">
      </el-tab-pane>
      <el-tab-pane :label="`我的(${myCount??'0'})`"
                   v-if="type==='my'">
      </el-tab-pane>
      <el-tab-pane :label="`我组的(${counts.total??'0'})`"
                   v-if="type==='my'&&userPermissions.indexOf('inquiry_owner_group_projects') !== -1">
      </el-tab-pane>
    </el-tabs>
    <div class='default-table'>
      <el-table :data='tableData' border
                stripe
                style='width: 100%'>
        <el-table-column
          align='center'
          label='序号'
          type='index'
          width='60'
        >
        </el-table-column>
        <el-table-column
          align='center'
          label='创建日期'
          prop='created_at'
          width='100'
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align='center'
          label='项目'
          min-width='200'
          prop='name'
          show-overflow-tooltip
        >
          <template slot-scope='{row}'>
            <el-link @click='detailPage(row.id)' size='mini'
                     type='primary' v-if="userPermissions.indexOf('inquiry_project_detail') !== -1">
              <span>{{ row.name }}</span>
            </el-link>
            <span v-else>{{ row.name }}</span>

          </template>
        </el-table-column>
        <el-table-column
          align='center'
          label='是否定向'
          prop='is_assign'
          width='100'
        >
        </el-table-column>
        <el-table-column
          align='center'
          label='意向达人'
          prop='intentions'
          width='100'
          show-overflow-tooltip
        >
          <template slot-scope='{row}'>
                            <span :key='i.id' v-for='i in row.intentions'>
                           {{ i.nickname }}
                       </span>
          </template>
        </el-table-column>
        <el-table-column
          align='center'
          label='品牌'
          prop='brand'
          show-overflow-tooltip
          width='100'
        >

        </el-table-column>
        <el-table-column
          align='center'
          label='产品'
          prop='product'
          show-overflow-tooltip
          width='100'
        >
        </el-table-column>
        <el-table-column
          align='center'
          label='所属集团'
          prop='group.display_name'
          show-overflow-tooltip
          width='100'
        >
        </el-table-column>
        <el-table-column
          align='center'
          label='是否寄回'
          prop='is_send_back'
        >

        </el-table-column>
        <el-table-column
          align='center'
          label='合作平台'
          prop='platforms'
          show-overflow-tooltip
        >
          <template slot-scope='{row}'>
            {{ String(row.platforms) }}
          </template>
        </el-table-column>
        <el-table-column
          align='center'
          label='是否平台下单'
          prop='is_platform_pay'
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align='center'
          label='合作形式'
          prop='coop_id'
        >
          <template slot-scope='{row}'>
            {{ row.coop?.display_name || '暂无' }}
          </template>
        </el-table-column>
        <el-table-column
          align='center'
          label='是否佣金、挂车'
          prop='is_cart'
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align='center'
          label='挂车、佣金说明'
          prop='cart_rate'
          show-overflow-tooltip
        >
          <template slot-scope='{row}'>
            {{ row.cart_rate + '%' || '' }}
          </template>
        </el-table-column>
        <el-table-column
          align='center'
          label='是否授权平台'
          prop='is_auth_price'
          show-overflow-tooltip
        >
        </el-table-column>

        <el-table-column
          align='center'
          label='期望档期'
          prop='expect_start'
          width='180'
          show-overflow-tooltip
        >
          <template slot-scope='{row}'>
            {{ row?.expect_start.slice(0, 10) }}至{{ row?.except_end.slice(0, 10) }}
          </template>
        </el-table-column>
        <!--        v-if="userPermissions.indexOf('inquiry_all_projects') !== -1"-->
        <el-table-column
          align='center'
          label='创建人'
          width='100'
          prop='create_user.nickname'
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align='center'
          label='所属部门'
          prop='dpt.name'
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align='center'
          label='报名时间'
          prop='apply_start'
          width='180'
          show-overflow-tooltip
        >
          <template slot-scope='{row}'>
            {{ row?.apply_start.slice(0, 10) }}至{{ row?.apply_end.slice(0, 10) }}
          </template>
        </el-table-column>
        <el-table-column
          align='center'
          label='项目状态' prop='status'
          show-overflow-tooltip
        >
          <template slot-scope='{row}'>
            {{ row?.status == 1 ? '进行中' : row?.status == 2 ? '已结束' : row?.status == 3 ? '已作废' : '已完结' }}
          </template>
        </el-table-column>
        <el-table-column
          align='center'
          label='报名状态' prop='is_apply'
          show-overflow-tooltip
          width='80'
        >
          <template slot-scope='{row}'>
            {{ row?.is_apply == 1 ? '报名中' : row?.is_apply == 2 ? '不可报名' : '结束报名' }}
          </template>
        </el-table-column>
        <el-table-column
          align='center' label='报名人数'
          prop='details_count'
          show-overflow-tooltip
          width='80'
        >
        </el-table-column>
        <el-table-column fixed='right' header-align='center' align='left' label='操作' width='180'>
          <template slot-scope='{row}'>
            <el-link @click='handleEdit(row.id)' icon='el-icon-edit' size='mini'
                     type='primary'
                     v-if="row?.status!=3&&row?.status!=2&&userPermissions.indexOf('inquiry_update') !== -1">
              编辑
            </el-link>
            &nbsp;
            <el-link @click="handleVoid(row,'cancel')" icon='el-icon-s-release'
                     size='mini' type='primary'
                     v-if="row.details_count==0&&row.status!=3&&userPermissions.indexOf('inquiry_cancel') !== -1">
              作废
            </el-link>
            <el-link @click="handleVoid(row,'del')" icon='el-icon-s-release'
                     size='mini' type='primary' v-if="userPermissions.indexOf('inquiry_delete') !== -1">删除
            </el-link>
          </template>
        </el-table-column>
      </el-table>
      <el-row>
        <el-col :span='24' style='text-align: right'>
          <Pagination :limit.sync='pageInfo.page_size'
                      :page.sync='pageInfo.current_page'
                      :total='pageInfo.total'
                      @pagination='emitData()' />
        </el-col>
      </el-row>
    </div>

    <!--    添加-->
    <CreateEdit :drawerVisible.sync='drawerVisible' :rowId='rowId'></CreateEdit>
    <!--作废确认框-->
    <el-dialog
      :title="handleData.type === 'cancel'?`请输入（${handleData.data?.name}）作废原因`:`请输入要删除的项目名称（${handleData.data?.name}）`"
      :visible.sync='dialogVisible' height='30%' width='30%'>

      <el-input
        clearable
        placeholder='请输入'
        v-model='cancel_reason'>
        <i :class='changeBlur' :style="changeBlur=='el-icon-check'?{color:'#67C23A'}:''" slot='suffix'
           style='line-height: 30px'></i>
      </el-input>
      <span style='font-size: 12px;display: block;float: right;margin-top: 12px;color: red'
            v-if="changeBlur=='el-icon-check'">删除需谨慎</span>

      <span class='dialog-footer' slot='footer'>
            <el-button @click='dialogVisible = false'>取 消</el-button>
            <el-button @click='cancel' type='primary'>确 定</el-button>
  </span>
    </el-dialog>
    <!--    导出进度-->
    <el-dialog
      :visible.sync='downloadLoading'
      width='50%'
      :destroy-on-close='true'
      :close-on-click-modal='false'
      :show-close='loadingEnd'
      @close='initDown'
    >
      <div class='loadingBox'>
        <div class='left'>
          <video width='80%' height='80%'
                 :src='require(`@/assets/loading/bg.mp4`)'
                 muted='true'
                 autoplay='true' auto-rotate='false' playsinline='true' x5-playsinline='true'
                 webkit-playsinline='true' t7-video-player-type='inline' crossorigin='anonymous'
                 loop='true'></video>
        </div>
        <div class='right'>
          <el-progress :width='250' style='margin-top: 30px' type='dashboard'
                       :percentage='percentage>100? 100 : percentage'
                       :color='colors'></el-progress>
        </div>
        <br />

      </div>
      <div class='bottom'>
        <h1>
          {{ loadingEnd ? `内容导出成功，共计【${downDataList.length}】条数据` : '内容导出中，请勿刷新或关闭窗口' }}
        </h1>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import CreateEdit from '../CreateEdit'
import InfoDetail from '../InfoDetail'
import { mapGetters } from 'vuex'
import Search from './search'
import * as XLSX from 'xlsx-js-style'

export default {
  name: 'index',
  components: { CreateEdit, InfoDetail, Search },
  computed: {
    ...mapGetters(['userPermissions', 'userInfo']),
    changeBlur() {
      let retunData = ''
      if (this.handleData.type === 'cancel') {
        retunData = 'el-icon-edit'
      } else {
        if (this.cancel_reason != this.handleData.data?.name) {
          retunData = 'el-icon-edit'
        } else
          retunData = 'el-icon-check'
      }
      return retunData
    }
  },
  data() {
    return {
      drawerVisible: false,
      rowId: 'add',
      searchDatas: {},
      pageInfo: {
        total: 0,
        page_size: 15,
        offset: 0,
        current_page: 1
      },
      dialogVisible: false,
      cancel_reason: '',
      handleData: {},
      activeName: '',
      counts: '',
      downDataList: [],
      downloadLoading: false,
      percentage: 0,
      colors: [
        { color: '#f56c6c', percentage: 20 },
        { color: '#e6a23c', percentage: 40 },
        { color: '#5cb87a', percentage: 60 },
        { color: '#1989fa', percentage: 80 },
        { color: '#6f7ad3', percentage: 100 }
      ],
      loadingEnd: false,
      exportId: null
    }
  },
  props: {
    tableData: {
      type: Array,
      required: true
    },
    tablePagination: {
      type: Object
    },
    type: {
      type: String
    },
    myCount: {
      type: Number
    },
    isActive: {
      type: [Number, String]
    }
  },
  watch: {
    drawerVisible: {
      handler(val) {
        if (!val) {
          this.emitData()
        }
      }
    },
    tablePagination: {
      handler(val) {
        this.pageInfo = val
      }
    },
    tableData: {
      handler(val) {
        if (!val) {
          this.tableData = val
        }
      }
    }
  },
  methods: {
    initDown() {
      this.loadingEnd = false
      this.percentage = 0
      this.downDataList = []
    },

    async startRecordExport() {
      let data = {
        'condition': { ...this.searchDatas },
        'type': this.type
      }
      return await this.$api.recordExportInquiry(data)
    },
    async endRecordExport() {
      let data = {
        'id': this.exportId,
        'rows_count': this.downDataList.length
      }
      await this.$api.recordExportInquiry(data)
    },
    // 下载表格
    async exportExcel() {
      this.downloadLoading = true
      this.exportId = null
      //记录导出信息
      let id = await this.startRecordExport()
      if (id) {
        this.exportId = id
        await this.getAllData()
      }

    },

    async getAllData() {
      let pageData = {
        page_size: 100,
        current_page: this.pageInfo.current_page,
        total: this.pageInfo.total
      }

      await this.getDownList(pageData)
      if (this.downDataList.length != 0 && this.downDataList.length === pageData.total) {
        // const xlsxParam = { raw: false } //转换成excel时，使用原始的格式
        /* 生成工作表 */
        const workbook = XLSX.utils.book_new()
        let header = ['序号', '创建日期', '项目', '是否定向', '意向达人', '品牌', '产品'
          , '所属集团', '是否寄回', '合作平台', '是否平台下单', '合作形式', '是否佣金、挂车', '挂车、佣金说明'
          , '是否授权平台', '期望档期', '创建人', '所属部门', '报名时间', '项目状态', '报名状态', '报名人数']
        let data = this.downDataList.map((item, i) => {
          return {
            '序号': i + 1,
            '创建日期': item.created_at,
            '项目': item.name,
            '是否定向': item.is_assign,
            '意向达人': item?.kols,
            '品牌': item.brand,
            '产品': item.product,
            '所属集团': item?.group?.display_name || '',
            '是否寄回': item.is_send_back,
            '合作平台': String(item.platforms),
            '是否平台下单': item.is_platform_pay,
            '合作形式': item?.coop?.display_name,
            '是否佣金、挂车': item.is_cart,
            '挂车、佣金说明': item.cart_rate + '%',
            '是否授权平台': item.is_auth_price,
            '期望档期': `${item?.expect_start.slice(0, 10)} 至 ${item?.except_end.slice(0, 10)}`,
            '创建人': item?.create_user?.nickname || '',
            '所属部门': item?.dpt?.name,
            '报名时间': `${item?.apply_start.slice(0, 10)} 至 ${item?.apply_end.slice(0, 10)}`,
            '项目状态': item.status,
            '报名状态': item.is_apply,
            '报名人数': item?.details_count

          }
        })
        let ws = XLSX.utils.json_to_sheet(data, { header: header })
        ws = this.worksheetStyle(ws)
        XLSX.utils.book_append_sheet(workbook, ws, '询单列表')
        XLSX.writeFile(workbook, `询单列表.xlsx`)
        this.loadingEnd = true
        // setTimeout(() => {
        //   this.downloadLoading = false
        // }, 2000)
      }
    },
    worksheetStyle: function(worksheet) {
      // 表样式设置
      const colsLength = 22
      let cols = [] // 设置每列的宽度
      // wpx 字段表示以像素为单位，wch 字段表示以字符为单位
      for (let i = 0; i <= colsLength; i++) {
        let col = {}
        if (i == 0) {
          col.wch = 10
        } else {
          col.wch = 20
        }
        cols.push(col)
      }
      worksheet['!cols'] = cols // 设置列宽信息到工作表
      //以下是样式设置，样式设置放在组织完数据之后，xlsx-js-style的核心API就是SheetJS的
      Object.keys(worksheet).forEach(key => {
        // 非!开头的属性都是单元格
        if (!key.startsWith('!')) {
          if (['A1', 'B1', 'C1', 'D1', 'E1', 'F1', 'G1', 'H1', 'I1', 'J1', 'K1', 'L1', 'M1', 'N1', 'O1', 'P1', 'Q1', 'R1', 'S1', 'T1', 'U1', 'V1'].includes(key)) {
            worksheet[key].s = {
              font: {
                sz: '12',
                name: 'Microsoft YaHei'
              },
              alignment: {
                horizontal: 'center',
                vertical: 'center',
                wrapText: true
              },
              fill: {
                fgColor: { rgb: 'fce2eb' }
              }
            }
            worksheet[key].t = 's'
          } else {
            worksheet[key].s = {
              font: {
                sz: '14'
              },
              alignment: {
                horizontal: 'center',
                vertical: 'center',
                wrapText: true
              }
            }
            worksheet[key].t = 'n'
          }
        }

      })

      return worksheet
    },

    async getDownList(pageData) {
      let searchCond = { ...this.searchDatas, current_page: pageData.current_page, page_size: pageData.page_size }
      let list = []
      let page_info = {}

      if (this.type === 'all') {
        let data = await this.$api.listInquiryProject(Object.assign(searchCond))
        list = data.list
        page_info = data.page_info

      }
      if (this.type === 'my') {
        if (Number(this.isActive) === 0) {
          let data = await this.$api.listInquiryOwner(Object.assign(searchCond))
          list = data.list
          page_info = data.page_info

        } else {
          let data = await this.$api.ownerGroup(Object.assign(searchCond))
          list = data.list
          page_info = data.page_info
        }
      }

      if (this.pageInfo.current_page === 1) {
        if (list.length === 0) {
          this.$message.error('暂无数据')
          this.downloadLoading = false
          return false
        }
      }
      list = list.map((row) => {
        row.status = row?.status == 1 ? '进行中' : row?.status == 2 ? '已结束' : row?.status == 3 ? '已作废' : '已完结'
        row.is_apply = row?.is_apply == 1 ? '报名中' : row?.is_apply == 2 ? '不可报名' : '结束报名'
        let arr = ['is_send_back', 'is_platform_pay', 'is_auth_price', 'is_assign', 'is_cart']
        arr.forEach((i) => {
          row[i] = row?.[i] == 2 ? '否' : '是'
        })
        if (row?.intentions?.length > 0) {
          row.kols = []
          row.intentions.forEach((item) => {
            row.kols.push(item.nickname)
          })
          row.kols = row.kols.toString()
        }
        return row
      })
      this.downDataList = [...this.downDataList, ...list]
      pageData.total = page_info.total
      pageData.current_page = page_info.current_page

      this.percentage = Math.round((pageData.current_page / (page_info.total / 100)) * 100)
      if (this.downDataList.length < pageData.total) {
        pageData.current_page++
        await this.getDownList(pageData)
      } else {
        // 数据加载完毕，通知后台记录导出行为
        //记录导出信息
        await this.endRecordExport()
        return true
      }


    },
    //
    handleClick() {
      this.$emit('activeName', this.activeName)
    },
    searchData(val) {
      let cond = { ...this.pageInfo }
      let name = ['name', 'status', 'brand', 'created_start', 'created_end', 'expect_start', 'expect_end', 'is_apply', 'created_by']
      name.forEach((type) => {
        if (val[type] !== '') {
          cond[type] = val[type]
        }
      })
      if (val.intentions?.length !== 0) {
        cond['intentions'] = val.intentions
      }
      if (val.platforms?.length !== 0) {
        cond['platforms'] = val.platforms
      }
      cond.current_page = 1
      this.searchDatas = cond
      this.$emit('getTableData', this.searchDatas)
    },
    emitData() {
      let searchData = { ...this.searchDatas, ...this.pageInfo }
      this.$emit('getTableData', searchData)
    },
    addTable() {
      this.rowId = 'add'
      this.drawerVisible = true
    },
    handleEdit(id) {
      this.rowId = id
      this.drawerVisible = true
    },

    handleVoid(rowId, type) {
      this.handleData = { data: rowId, type: type }
      this.dialogVisible = true
    },
    async cancel() {
      // "status": 2 //1:进行中 2:已完结 3:已作废
      if (this.handleData.type === 'cancel') {
        if ((this.cancel_reason || undefined) != undefined) {
          let statusData = {
            id: this.handleData.data.id,
            status: 3,
            cancel_reason: this.cancel_reason
          }
          let id = await this.$api.cancelInquiryStatus(statusData)
          //listInquiryOwner
          if (id) {
            this.$notify.success('作废成功')
            this.dialogVisible = false
            this.emitData()
          }
        } else this.$notify.warning('请输入作废原因')
      } else {
        if (this.cancel_reason === this.handleData.data?.name) {
          let statusData = {
            id: this.handleData.data.id
          }
          let id = await this.$api.delInquiry(statusData)
          //listInquiryOwner
          if (id) {
            this.$notify.success('删除成功')
            this.dialogVisible = false
            this.emitData()
          }
        } else this.$notify.warning('项目名称不正确，删除失败')
      }


    },
    detailPage(id) {
      this.$router.push({
        name: 'inquiriesDetail',
        params: { id: id }
      })
    },
    moneyFormat(value) {
      return this.$utils.numberFormat(value, 2, '.', ',', 'round')
    },
    async getCount() {
      if (this.userPermissions.indexOf('inquiry_owner_group_projects') !== -1) {
        this.counts = await this.$api.myCounts()
      }
    }
  },
  mounted() {
    this.pageInfo = this.tablePagination || {}
    this.getCount()
  }
}
</script>

<style lang='scss' scoped>
.loadingBox {
  width: 80%;
  margin: 0 auto;
  display: flex;

}

.bottom {
  width: 80%;
  text-align: center;
  margin: 0 auto;
  color: #ff3176;
}
</style>
